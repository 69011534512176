import './App.scss';
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import { FaXTwitter } from "react-icons/fa6";
import { BiCheck } from "react-icons/bi";
import Accordion from 'react-bootstrap/Accordion';
import Image from 'react-bootstrap/Image';


const Mailto = ({ email, subject = '', body = '', children }) => {
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

  return <a className="App-link" href={`mailto:${email}${params}`}>{children}</a>;
};

function App() {
  return (
    <Container>
      <div className="App"> 
        <header className="App-header">
          <img src="/byteCTA_wordmark_white_transparent.png" className="App-logo" alt="logo" />
        </header>  
        <main>
          <Container>
            <Row>
              <h4 className="text-center">
                byteCTA drives engagement of your web3 project by extending your EVM contract with NFT rewards for engagement with social media --- a Call-To-Action for blockchain.
              </h4>             
            </Row>
              <br />
              <br />  
              <br />
              <br />  
            <Row>
              <Col sm={0} md={3}></Col>
              <Col sm={6} md={4}>
                <p className="text-nowrap"><BiCheck className="icon-green me-2" />Increase Awareness</p>
                <p className="text-nowrap"><BiCheck className="icon-green me-2" />Task and Reward Engagement</p>
                <p className="text-nowrap"><BiCheck className="icon-green me-2" />Harness Social Media, X</p>
                <p className="text-nowrap"><BiCheck className="icon-green me-2" />Gain Tweets and Mentions</p>                                  
              </Col>      
              <Col sm={6} md={4}>
                <p className="text-nowrap"><BiCheck className="icon-green me-2" />Simplify Development</p>
                <p className="text-nowrap"><BiCheck className="icon-green me-2" />Minimum Code</p>
                <p className="text-nowrap"><BiCheck className="icon-green me-2" />Import Solidity Contract</p>
                <p className="text-nowrap"><BiCheck className="icon-green me-2" />Add UI Components and JS</p>
              </Col>  
              <Col sm={0} md={1}></Col>
            </Row>  
            <br />
            <br />
            <br />
            <br />                                                  
            <Row>
              <Col sm={12}>
                Step 1: Create your byteCTA Account
                <ListGroup>
                  <ListGroup.Item>              
                    Register your project with your web3 wallet
                  </ListGroup.Item>
                  <ListGroup.Item>              
                    Manage your project with the byteCTA dashboard
                  </ListGroup.Item>                  
                </ListGroup>   
              </Col>
            </Row>        
            <br />
            <br />                     
            <Row>
              <Col sm={12}>
                Step 2: Integrate your EVM project with byteCTA
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Implement the byteCTA solidity contract on your EVM 1155 contract</Accordion.Header>
                    <Accordion.Body>           
                      <Image className="image-max-" src="byteCTA_Solidity_Web.png" alt="byteCTA Solidity Contract" fluid/>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>  
              </Col>           
            </Row>
            <br />
            <br />
            <Row>
              <Col sm={12}>
                Step 3: Allow users to Register, Tweet, and Claim
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Enhance your UI with the necessary components</Accordion.Header>
                    <Accordion.Body>           
                      <Image className="image-max-width" src="byteCTA_3_1_Web.png" alt="byteCTA Solidity Contract" fluid/>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Enhance your UI with byteCTA javascript for API calls</Accordion.Header>
                    <Accordion.Body>           
                      <Image className="image-max-width" src="byteCTA_3_2_Web.png" alt="byteCTA Solidity Contract" fluid/>
                    </Accordion.Body>
                  </Accordion.Item> 
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Utilize byteCTA javascript to enable NFT reward claims</Accordion.Header>
                    <Accordion.Body>           
                      <Image className="image-max-width" src="byteCTA_3_3_Web.png" alt="byteCTA Solidity Contract" fluid/>
                    </Accordion.Body>
                  </Accordion.Item>                                   
                </Accordion>                  
              </Col>
            </Row>     
            <br />
            <br />
            <Row>
              <Col sm={12}>
                Optional: Leverage Manual Tasks
                <ListGroup >
                  <ListGroup.Item>
                    Manually whitelist reward claims, rather than depending social media message tasks
                  </ListGroup.Item> 
                  <ListGroup.Item>
                  Call byteCTA API to whitelist an address for NFT reward claim
                  </ListGroup.Item>                   
                </ListGroup>                                            
              </Col>
            </Row>                   
            <br />
            <br />
            <br />
            <br />
            <Row>
              <h4 className="text-center">Project Use-Cases</h4>
              <br />
              <br />
              <br />
              <Col sm={12}>
                <Card className="mx-auto" border="light" style={{ width: '18rem' }}>
                  <Card.Img className="p-5" variant="top" src="/logos/bbnfr-logo-192.png" />
                  <Card.Body>
                    <Card.Title>blockchainBonfire</Card.Title>
                    <Card.Text>
                      We are a collective gathering at the blockchainBonfire. Some are here to party, whilst others for better parties to come. ...a quest to explore Cartesian NFTs..
                    </Card.Text>
                    <Card.Link href="https://www.blockchainBonfire.com" target="_blank">www.blockchainBonfire.com</Card.Link>
                  </Card.Body>
                </Card>              
              </Col>
            </Row>          
            <br />
            <br />
            <br />
            <br />    
            <Row className="text-center">
              <h4 className="text-center">Supported Chains</h4>
              <br />
              <br />
              <br />
              <Col md={2}><img src="/logos/arb-logo.png" className="supportedchain-logo p-2" alt="logo" /></Col>
              <Col md={2}><img src="/logos/avax-logo.png" className="supportedchain-logo p-2" alt="logo" /></Col>
              <Col md={2}><img src="/logos/base-logo.png" className="supportedchain-logo p-2" alt="logo" /></Col>
              <Col md={2}><img src="/logos/bsc-logo.png" className="supportedchain-logo p-2" alt="logo" /></Col>
              <Col md={2}><img src="/logos/celo-logo.png" className="supportedchain-logo p-2" alt="logo" /></Col>
              <Col md={2}><img src="/logos/eth-logo.png" className="supportedchain-logo p-2" alt="logo" /></Col>              
            </Row>
            <Row className="text-center">
              <Col md={2}><img src="/logos/ethw-logo.png" className="supportedchain-logo p-2" alt="logo" /></Col>
              <Col md={2}><img src="/logos/ftm-logo.png" className="supportedchain-logo p-2" alt="logo" /></Col>
              <Col md={2}><img src="/logos/harmony-logo.png" className="supportedchain-logo p-2" alt="logo" /></Col>
              <Col md={2}><img src="/logos/linea-logo.png" className="supportedchain-logo p-2" alt="logo" /></Col>
              <Col md={2}><img src="/logos/op-logo.png" className="supportedchain-logo p-2" alt="logo" /></Col>
              <Col md={2}><img src="/logos/polygon-logo.png" className="supportedchain-logo p-2" alt="logo" /></Col>              
            </Row>                                    
            <br />
            <br />
            <br />
            <br />                       
            <Row className="text-center">
              <p>Do you want to integrate your project with byteCTA?</p>
              <Mailto email="contact@byteCTA.com" subject="byteCTA Inquiry" body="">
                contact@byteCTA.com
              </Mailto>                 
            </Row>
          </Container>          
        </main> 
        <footer>
          <div className="float-end"> 
              <a target="_blank" href="https://x.com/byteCTA" rel="noreferrer">
                  <FaXTwitter className="icon-green" />
              </a>
          </div>           
        </footer>         
      </div>
    </Container>    
  );
}

export default App;
